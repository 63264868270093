#map-container {
    position: relative;
}

#map-container .bg {
    width: 100%;
}

.loc-container, .loc, .icon-active {
    position: absolute;
}

.loc.hover, .loc-container:hover .loc.normal {
    visibility: hidden;
}

.loc-container:hover .loc.hover {
    visibility: visible;
}

.loc-container:hover {
    cursor: pointer;
}

.loc-container .disabled:hover {
    cursor: auto;
    cursor: initial;
}

.icon-active {
    -webkit-transform: translate(20%, 10%);
            transform: translate(20%, 10%);
    top:0;
    left: 50%;
    width:100%;
    height: 100%;
}

@-webkit-keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
    40% {
        -webkit-transform: translateX(-1%);
                transform: translateX(-1%);
    }
    60% {
        -webkit-transform: translateX(-0.5%);
                transform: translateX(-0.5%);
    }
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
    40% {
        -webkit-transform: translateX(-1%);
                transform: translateX(-1%);
    }
    60% {
        -webkit-transform: translateX(-0.5%);
                transform: translateX(-0.5%);
    }
}

.bounce {
    -webkit-animation-name: bounce;
            animation-name: bounce;
    -webkit-animation-duration: 1.5s;
            animation-duration: 1.5s;
	-webkit-animation-fill-mode: both;
	        animation-fill-mode: both;
	-webkit-animation-timing-function: linear;
	        animation-timing-function: linear;
	-webkit-animation-iteration-count: infinite;
	        animation-iteration-count: infinite;
}
